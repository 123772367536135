* {
	box-sizing: border-box;
}

body, html { 
  margin: 0;
	padding: 0;
	// font-family: 'IBM Plex Sans', sans-serif;
	font-family: 'Asul', sans-serif;
}

.wrapper {
  height: 100vh;
  overflow-x: hidden;
	overflow-y: auto;
	perspective: 100px;
	-webkit-perspective: 100px;
}

.section {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

	img {
		max-width: 100vw;
	}
}

.parallax {
	display: grid;
	align-content: center;
	justify-items: center;
	height: 100%;
	width: 100%;
	transform-style: preserve-3d;
	z-index: -1;

	.pizza {
		transform: translate3d(0, 0, -21px);
		transform-origin: center center;
		grid-area: 1 / 1 / 2 / 2;
		transform-style: preserve-3d;
	}

	.background {
		width: 100vw;
		height: 100vh;
		transform: translate3d(0, 0, -51px) scale(1.52);
		transform-style: preserve-3d;
		object-fit: cover;
		object-position: 46% 50%;
		grid-area: 1 / 1 / 3 / 2;
	}

	header	{
		grid-area: 2 / 1 / 3 / 2;
		text-align: center;
		transform: translate3d(0,0,-10px) scale(1.1);
		box-shadow: 0px 0px 0px hsl(207, 97%, 14%);
		color: #fff;
		position: relative;
		font-size: min(2rem, 5vw);

		h1 {
			font-size: 2em;
		}

		h2 {
			font-size: 1em;
		}

		&::after {
			content: "";
			position: absolute;
			height: 100%;
			transform: scale(1.5);
			filter: blur(68px);
			width: 100%;
			background: #00000038;
			z-index: -1;
			left: 0;
			top: 0;
	}
	}
}

.content {
	background: #fff;
	padding: 0 0;
	z-index: 1000;

	.card {
		margin: 0 auto;
		box-shadow: 0px 1px 12px rgba(0, 0, 0, 0.2);
		max-width: 800px;
		overflow: hidden;
		margin-bottom: 2rem;

		h1 {
			text-align: center;
			font-size: 20px;
			font-weight: 500;
			margin: 0;
			background: rgb(222, 227, 231);
			padding: 8px 0;
		}
		.content {
			padding: 12px;
			
			>img {
				max-width: 100%;
			}
		}
		
		.caption {
			display: flex;
			font-size: 16px;
			align-items: center;
			padding-bottom: 22px;

			>:first-child {
				// flex-grow: 1;
				flex-shrink: 1;
				width: 100px;
				margin-right: 6px;
			
			}
			>:last-child {
				flex-grow: 4;
			}
		}

	}

}

footer {
	text-align: center;
	padding: 20px 0 ;
}